<template>
  <item-card :url="`/catalog/${order.id}`" :blank="true">
    <template v-slot:title>
      <div class="flex flex-wrap items-center mb-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mr-5">
          {{order.name}}
        </h3>
      </div>
    </template>

    <template v-slot:content>
      <ItemText title="SKU" :text="order.sku" />
      <ItemText title="Kasete" :text="order.is_for_production ? 'Jā' : 'Nē'" />
      <ItemText title="Pasūtīto preču skaits" :text="order.quantity" />

      <template v-if="order.image_url">
        <div class="w-20 h-20">
          <img :src="order.image_url" :alt="order.name" class="w-full object-contain">
        </div>
      </template>
    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleCustomerOrder",
  components: {
    ItemCard,
    ItemText,
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style>

</style>